import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, computed, inject, input, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../../_generated';
import * as fromFeatureFlags from '../../../feature-flags';
import * as fromAssistantIcon from './assistant-icon.helper';

@Component({
  selector: 'squadbox-assistant-card',
  imports: [
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.TooltipDirective,
    fromSeriousSystem.UseAssistantCustomIconDirective,
    fromSeriousSystem.UseAssistantIconDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.DropdownMenuComponent,
    fromFeatureFlags.FeatureFlagsDirective,
    TranslateModule,
    CdkMenuTrigger,
  ],
  template: `
    <div
      [class.grid-template-area]="'logo'"
      class="
        flex items-center justify-center mr-2
        size-12 rounded-full
        bg-primary-500/5 text-primary-500/30
        outline outline-primary-500/15 outline-offset-4
        cursor-pointer
      "
      [class.pointer-events-none]="!isEditable()"
      (click)="handleEditClickedCTA()"
    >
      @switch (fromAssistantIcon.getLogoType(logo())) { @case ('assistant') {
      <svg
        [class.opacity-50]="isProcessingOrFailed()"
        [sdUseAssistantIcon]="fromAssistantIcon.getAssistantIcon(logo())"
        class="size-5"
      ></svg>
      } @case ('custom') {
      <svg
        [class.opacity-50]="isProcessingOrFailed()"
        [sdUseAssistantCustomIcon]="
          fromAssistantIcon.getAssistantCustomIcon(logo())
        "
        class="size-5"
      ></svg>
      } @default {
      <img
        [class.opacity-50]="isProcessingOrFailed()"
        [src]="logo()"
        alt="Assistant Logo"
        class="size-5"
      />
      } }
    </div>
    <div
      [class.grid-template-area]="'text'"
      [class.opacity-50]="isProcessingOrFailed()"
      class="flex flex-col flex-grow gap-1 cursor-pointer overflow-hidden"
      [class.pointer-events-none]="!isEditable()"
      (click)="handleEditClickedCTA()"
    >
      <div
        class="
          flex flex-col tablet-landscape:flex-row tablet-landscape:items-center 
          gap-1 tablet-landscape:gap-2 
          font-semibold text-neutral-700
        "
      >
        <span class="typo-p3 overflow-hidden text-ellipsis">{{ name() }}</span>
        @if (categoryLabel()) {
        <div class="typo-footer bg-neutral-100 p-1 rounded w-fit">
          {{ categoryLabel() }}
        </div>
        }
      </div>
      <div class="typo-caption text-neutral-500 line-clamp-2">
        {{ description() }}
      </div>
    </div>
    <div
      [class.grid-template-area]="'action'"
      class="pl-0 tablet-landscape:pl-10"
    >
      @switch (status()) { @case ('processing') {
      <div
        [sdTooltip]="'ASSISTANTS.CARD.PROCESSING_STATUS_TOOLTIP' | translate"
      >
        <div
          sdButton
          class="pointer-events-none"
          color="primary"
          variant="tonal"
          size="xs"
        >
          {{ 'ASSISTANTS.CARD.PROCESSING_STATUS' | translate }}
        </div>
      </div>
      } @case ('failed') {
      <div [sdTooltip]="'ASSISTANTS.CARD.FAILED_STATUS_TOOLTIP' | translate">
        <div
          sdButton
          class="pointer-events-none"
          color="error"
          variant="tonal"
          size="xs"
        >
          {{ 'ASSISTANTS.CARD.FAILED_STATUS' | translate }}
        </div>
      </div>
      } @case ('ready') {
      <div class="flex items-center gap-2">
        @if (!isAdded()) {
        <button sdButton color="primary" size="xs" (click)="addClicked.emit()">
          {{ 'ASSISTANTS.CARD.ADD_ASSISTANT' | translate }}
        </button>
        } @else {
        <button
          sdButton
          color="neutral"
          variant="outline"
          size="xs"
          (click)="removeClicked.emit()"
        >
          {{ 'ASSISTANTS.CARD.REMOVE_ASSISTANT' | translate }}
        </button>
        }
        <button
          sdIconButton
          variant="icon"
          color="neutral"
          size="xs"
          [disabled]="category() === 'specialized'"
          [cdkMenuTriggerFor]="dropdown.menu"
          [squadboxFeatureFlag]="'ff-duplicate-assistant'"
        >
          <svg sdUseUIBasicIcon="more-horizontal"></svg>
        </button>
        <sd-dropdown-menu
          #dropdown
          [options]="dropdownMenuOptions()"
        ></sd-dropdown-menu>
      </div>
      } }
    </div>
  `,
  styles: [
    `
      :host {
        @apply grid grid-cols-[min-content_auto_min-content] gap-4 items-center;
        grid-template-areas: 'logo text action';
      }
    `,
  ],
})
export class AssistantCardComponent {
  private readonly translateService = inject(TranslateService);

  public readonly logo = input.required<string>();
  public readonly name = input.required<string>();
  public readonly description = input.required<string>();
  public readonly category =
    input.required<fromGenerated.AssistantView.CategoryEnum>();
  public readonly categoryLabel = input.required<string | null>();
  public readonly status =
    input.required<fromGenerated.AssistantView.StatusEnum>();
  public readonly isAdded = input(false);

  public readonly editClicked = output();
  public readonly addClicked = output();
  public readonly removeClicked = output();
  public readonly duplicateClicked = output();
  public readonly seeDetailsClicked = output();
  protected readonly fromAssistantIcon = fromAssistantIcon;

  public readonly isEditable = computed(() => !this.isProcessingOrFailed());
  public readonly isProcessingOrFailed = computed(() =>
    ['processing', 'failed'].includes(this.status())
  );

  public readonly dropdownMenuOptions = computed<
    fromSeriousSystem.DropdownMenuOption[]
  >(() => {
    const options: fromSeriousSystem.DropdownMenuOption[] = [];

    if (this.category() === 'custom') {
      options.push({
        label: this.translateService.instant(
          'ASSISTANTS.DROPDOWN_MENU.EDIT'
        ) as string,
        icon: 'edit' as fromSeriousSystem.UIBasicIconsType,
        action: () => this.editClicked.emit(),
      });
    }

    if (this.category() === 'essential') {
      options.push({
        label: this.translateService.instant(
          'ASSISTANTS.DROPDOWN_MENU.DETAILS'
        ) as string,
        icon: 'info' as fromSeriousSystem.UIBasicIconsType,
        action: () => this.seeDetailsClicked.emit(),
      });
    }

    if (['essential'].includes(this.category())) {
      options.push({
        label: this.translateService.instant(
          'ASSISTANTS.DROPDOWN_MENU.DUPLICATE'
        ) as string,
        icon: 'copy' as fromSeriousSystem.UIBasicIconsType,
        action: () => this.duplicateClicked.emit(),
      });
    }

    return options;
  });

  public handleEditClickedCTA(): void {
    if (this.isEditable()) {
      this.editClicked.emit();
    }
  }
}
