/* eslint-disable sonarjs/no-duplicate-string */
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LaunchDarklyFlagValue } from '../launch-darkly.client';
import { FeatureFlagsActions } from './feature-flags.actions';
import { hasAnyTrueValue } from './feature-flags.helper';
import {
  FeatureFlagEssentialSpecializedAssistants,
  FeatureFlagQuickActions,
} from './feature-flags.interfaces';

export const featureFlagsFeatureKey = 'featureFlags';

export enum FeatureFlagKeyEnum {
  'sample-feature' = 'sample-feature',
  'ff-create-ai-assistant-with-document-s' = 'ff-create-ai-assistant-with-document-s',
  'ff-display-document-sources' = 'ff-display-document-sources',
  'ff-document-viewer' = 'ff-document-viewer',
  'ff-bug-reporting-button-hs-users' = 'ff-bug-reporting-button-hs-users',
  'ff-document-viewer-sources-pin' = 'ff-document-viewer-sources-pin',
  'ff-document-viewer-highlight-text' = 'ff-document-viewer-highlight-text',
  'ff-quick-actions' = 'ff-quick-actions',
  'ff-navigate-between-highlights' = 'ff-navigate-between-highlights',
  'ff-essential-specialized-assistants' = 'ff-essential-specialized-assistants',
  'ff-duplicate-assistant' = 'ff-duplicate-assistant',
}
export type FeatureFlagKey = `${FeatureFlagKeyEnum}`;

export interface FeatureFlagView extends LaunchDarklyFlagValue {
  key: string;
  value: unknown;
}

export interface FeatureFlagsState extends EntityState<FeatureFlagView> {
  ready: boolean;
  initError: string | null;
  error: string | null;
}

export const entityAdapter: EntityAdapter<FeatureFlagView> =
  createEntityAdapter<FeatureFlagView>({
    selectId: (flag) => flag.key,
  });

export const initialFeatureFlagsState: FeatureFlagsState =
  entityAdapter.getInitialState({
    ready: false,
    initError: null,
    error: null,
  });

export const featureFlagsFeature = createFeature({
  name: featureFlagsFeatureKey,
  reducer: createReducer(
    initialFeatureFlagsState,
    on(FeatureFlagsActions.initClientSuccess, (state) => ({
      ...state,
      ready: true,
      initError: null,
    })),
    on(FeatureFlagsActions.initClientFailure, (state, { initError }) => ({
      ...state,
      initError: initError.message,
    })),
    on(FeatureFlagsActions.loadFeatureFlags, (state, { featureFlags }) =>
      entityAdapter.setAll(featureFlags, state)
    ),
    on(FeatureFlagsActions.onChangeSyncSuccess, (state, { featureFlags }) =>
      entityAdapter.upsertMany(featureFlags, state)
    ),
    on(
      FeatureFlagsActions.onChangeFFEssentialSpecializedAssistants,
      (state, { flag }) =>
        entityAdapter.updateOne(
          {
            id: FeatureFlagKeyEnum['ff-essential-specialized-assistants'],
            changes: {
              value: flag,
            },
          },
          state
        )
    ),
    on(FeatureFlagsActions.onError, (state, { error }) => ({
      ...state,
      error: error.message,
    }))
  ),
  extraSelectors: ({ selectFeatureFlagsState }) => {
    const entitySelectors = entityAdapter.getSelectors(selectFeatureFlagsState);
    const selectFeatureFlag = <T = boolean>(featureFlagKey: FeatureFlagKey) =>
      createSelector(
        entitySelectors.selectEntities,
        (featureFlags) => featureFlags[featureFlagKey]?.value as T
      );

    return {
      ...entitySelectors,
      selectFeatureFlag,
      selectQuickActions: createSelector(
        selectFeatureFlag<FeatureFlagQuickActions>('ff-quick-actions'),
        (ff) => {
          return {
            ...ff,
            hasAnyTrueValue: hasAnyTrueValue(ff),
            starsAi: {
              ...ff.starsAi,
              hasAnyTrueValue: hasAnyTrueValue(ff.starsAi),
              changeTone: {
                ...ff.starsAi.changeTone,
                hasAnyTrueValue: hasAnyTrueValue(ff.starsAi.changeTone),
              },
              improveWriting: {
                ...ff.starsAi.improveWriting,
                hasAnyTrueValue: hasAnyTrueValue(ff.starsAi.improveWriting),
              },
              translate: {
                ...ff.starsAi.translate,
                hasAnyTrueValue: hasAnyTrueValue(ff.starsAi.translate),
              },
            },
          };
        }
      ),
      selectEssentialSpecializedAssistants: createSelector(
        selectFeatureFlag<
          FeatureFlagEssentialSpecializedAssistants | undefined
        >('ff-essential-specialized-assistants'),
        (ff) => ff ?? ({} as FeatureFlagEssentialSpecializedAssistants)
      ),
    };
  },
});
